import { isLoggedIn } from '@/composables/auth_service';
import {createRouter, createWebHistory } from 'vue-router';

const routes = [
    {
        path: '/',
        component: () => import('../pages/Welcome.vue'),
        children: [
            {
                path: '',
                name: 'home',
                props: false,
                component: () => import('../pages/Home.vue')
            },
            {
                path: '/availability/:id',
                name: 'availability',
                props: false,
                component: () => import('../pages/Leads.vue')
            },
            {
                path: '/inventory',
                name: 'inventory',
                props: false,
                component: () => import('../pages/Inventory.vue')
            },
            {
                path: '/inventory/:id',
                name: 'car-details',
                props: false,
                component: () => import('../pages/CarDetails.vue')
            },
            {
                path: '/finance',
                name: 'finance',
                props: false,
                component: () => import('../pages/Finance.vue')
            },
        
            {
                path: '/sell-trade',
                name: 'sell-trade',
                props: false,
                component: () => import('../pages/SellTrade.vue')
            },
            {
                path: '/faqs',
                name: 'faqs',
                props: false,
                component: () => import('../pages/FAQs.vue')
            },
            {
                path: '/about-us',
                name: 'about-us',
                props: false,
                component: () => import('../pages/About.vue')
            },
            {
                path: '/privacy',
                name: 'privacy',
                props: false,
                component: () => import('../pages/PrivacyPage.vue')
            }
        ]
    },
    {
        path: '/admin',
        component: () => import('../pages/admin/AdminHome.vue'),
        props: true,
        children: [
            {
                path: '',
                name: 'Admin',
                component: () => import('../pages/admin/WelcomeAdmin.vue')
            },
            {
                path: '/admin/credit-application',
                name: 'Credit Application',
                component: () => import('../pages/admin/credit_application/CreditApplication.vue')
            },
            {
                path: '/admin/credit-application/view/:id',
                name: 'View Credit Application',
                props: true,
                component: () => import('../pages/admin/credit_application/CreditApplicationView.vue')
            },
            {
                path: '/admin/jd-power',
                name: 'JdPower',
                props: false,
                component: () => import('../pages/admin/inventoryJdpower/Inventory.vue')
            },
            {
                path: '/admin/jd-power/:id',
                name: 'Jd-power-car-details',
                props: false,
                component: () => import('../pages/admin/inventoryJdpower/CarDetails.vue')
            },
            {
                path: '/leads',
                name: 'Leads',
                props: true
            },
            {
                path: '/faqs',
                name: 'Faqs',
                props: true
            },
            {
                path: '/admin/contacts',
                name: 'Contacts',
                props: true,
                component: () => import('../pages/admin/contacts/ContactPage.vue')
            },
            {
                path: '/admin/contacts/:id',
                name: 'View Contact',
                props: true,
                component: () => import('../pages/admin/contacts/ContactViewPage.vue')
            },
            {
                path: '/admin/appraisal',
                name: 'Appraisal',
                props: true,
                component: () => import('../pages/admin/appraisal_tool/AppraisalToolView.vue')
            },
            {
                path: '/admin/appraisal/:id',
                name: 'View Appraisal Tool',
                props: true,
                component: () => import('../pages/admin/appraisal_tool/AppraisalTool.vue')
            },
            {
                path: '/admin/jd-power',
                name: 'JdPower',
                props: true,
                component: () => import('../pages/admin/jd_power/JdPowerPage.vue')
            },
            {
                path: '/admin/jd-power/:id',
                name: 'View JdPower',
                props: true,
                component: () => import('../pages/admin/jd_power/JdPowerViewPage.vue')
            },
            {
                path: '/admin/leads/',
                name: 'Leads',
                props: true,
                component: () => import('../pages/admin/lead/LeadPage.vue')
            },
            {
                path: '/admin/leads',
                name: 'Leads',
                props: true,
                component: () => import('../pages/admin/lead/LeadPage.vue')
            },
            {
                path: '/admin/leads/:id',
                name: 'View Leads',
                props: true,
                component: () => import('../pages/admin/lead/LeadViewPage.vue')
            },
            {
                path: '/admin/hero',
                name: 'Hero',
                props: true,
                component: () => import('../pages/admin/hero/HeroPage.vue')
            },
            {
                path: '/admin/hero/create',
                name: 'Create Hero',
                props: true,
                component: () => import('../pages/admin/hero/HeroCreatePage.vue')
            },
            {
                path: '/admin/hero/create/:id',
                name: 'Update Hero',
                props: true,
                component: () => import('../pages/admin/hero/HeroCreatePage.vue')
            },
            {
                path: '/admin/review',
                name: 'Reviews',
                props: true,
                component: () => import('../pages/admin/review/ReviewPage.vue')
            },
            {
                path: '/admin/review/:id',
                name: 'View Review',
                props: true,
                component: () => import('../pages/admin/review/ReviewPage.vue')
            },
            {
                path: '/subscriptions',
                name: 'Subscriptions',
                props: true
            }
        ],
        beforeEnter(to, from, next) {
            console.log(isLoggedIn(), 'sa /admin');
            if(!isLoggedIn) {
                console.log('asd')
                next('/auth/login');
            }
            else {
                next()
            }
        }
    },
    {
        path: '/auth/login',
        name: 'Login',
        component: () => import('../pages/auth/Login.vue'), 
        beforeEnter(to, from, next) {
            console.log(isLoggedIn(), 'sa /auth/login');
            if(!isLoggedIn) {
                next('/auth/login')
            }
            else {
                next();
            }
        }
    }

]

const router = createRouter({
    history: createWebHistory('/'),
    routes
})

export default router
