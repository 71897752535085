<template>
    <metainfo>
    <template 
      v-slot:title="{ content }">{{ content? `${content}  ` : `Zoom Motors of Bristol` }}
    </template>
    <template 
      v-slot:description="{ content }">{{ content? `${content} ` : `What our customers are saying "Amazing dealership! I truly left there feeling great abou` }}
    </template>
  </metainfo>
    <div>
        <router-view/>
    </div>
</template>

<script>
import { useMeta } from 'vue-meta'

export default {
  name: 'App',
  setup() {
    useMeta({
      title: '',
      description: 'What our customers are saying "Amazing dealership! I truly left there feeling great about my car purchase, which has never happened!"',
    })
  }, 
}
</script>