import { ref } from "vue"
import { http } from "./http_service";
import createStore from '@/store/index'
import { useRouter } from "vue-router";


export const authLogin = (form) => {
    const user = ref();
    const loadingLogin = ref(false);
    const errorLogin = ref()
    const router = useRouter();

    const login = async () => {
        await http().post('/auth/login', form).then(res => {
            if(res.status == 200) {
                setToken(res.data);
                user.value = res.data.user;
                
                router.push({name: 'Credit Application'})
            }
        }).catch(error => {
            console.log('Error in login -->', error);
            errorLogin.value = error.response.data
        })
    }

    return {
        user,
        login,
        loadingLogin,
        errorLogin
    }
}

export const authProfile = () => {
    const user = ref({});
    const loadingProfile = ref(false);
    const errorProfile = ref({})
    const router = useRouter();

    const loadProfile = async () => {
        loadingProfile.value = true;
        await http().get('/user/profile').then(res => {
            if(res.status === 200) {
                user.value = res.data.user
            }
        }).catch(error => {
            localStorage.removeItem('zoom');
            console.log(error)
            router.push('/auth/login')
        })
    }

    return {
        user,
        loadingProfile,
        errorProfile,
        loadProfile
    }
}

export const authLogout = async () => {
    await http().post('/user/logout').then(res => {
        localStorage.removeItem('zoom')
    });
}

export function isLoggedIn() {

    const token = localStorage.getItem('zoom');

    return token != null;
}

function setToken(data) {
    localStorage.setItem('zoom', data.token);
}

export const accessToken = () => {
    const token = localStorage.getItem('zoom');
    
    if(!token) return null;

    return token;
}