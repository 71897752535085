import { createApp } from 'vue'
import router from './router'
import App from './App.vue'
import './index.css'
import mitt from 'mitt';

import { createMetaManager, plugin as metaPlugin } from 'vue-meta'


import axios from 'axios';
import VueAxios from 'vue-axios';

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

const emitter = mitt();
const app = createApp(App);

app.config.globalProperties.emitter = emitter;
app.use(ElementPlus)


const instance = axios.create({
  baseURL: '/api',
  headers: {
    Accept: 'application/json',
    'Content-type': 'application/json',
  },
});

instance.CancelToken = axios.CancelToken;
instance.isCancel = axios.isCancel;

app.use(router)
app.use(createMetaManager())
app.use(VueAxios, instance);

app.mount('#app')

