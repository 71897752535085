import { authLogin, authLogout, authProfile } from "@/composables/auth_service";
import { createStore } from "vuex";


const store = createStore({
    state: {
        isLoggedIn: false,
        apiURL: '/api',
        serverPath: '/',
        userProfile: {},
        adminNavBar: [
            // { route: '/admin', name: 'Admin', label: 'Dashboard', is_active: false },
            { route: '/credit-application', name: 'Credit Application', label: 'Credit Application',  is_active: false  },
            // { route: '/leads', name: 'Leads', label: 'Leads',  is_active: false  },
            // { route: '/faqs', name: 'Faqs', label: 'FAQs',  is_active: false  },
            { route: '/admin/contacts', name: 'Contacts', label: 'Contacts',  is_active: false  },
            { route: '/admin/appraisal', name: 'Appraisal', label: 'Appraisal Tool',  is_active: false  },
            // { route: '/subscriptions', name: 'Subscriptions', label: 'Subscriptions',  is_active: false  },
            // { route: '/v-auto', name: 'VAuto', label: 'VAuto',  is_active: false  },
            // { route: '/jd-power', name: 'JdPower', label: 'JD Power',  is_active: false  },
            // { route: '/admin/jd-power', name: 'JdPower', label: 'JD Power',  is_active: false  },
            { route: '/admin/leads', name: 'Leads', label: 'Leads',  is_active: false  },
            { route: '/admin/reviews', name: 'Reviews', label: 'Reviews',  is_active: false  },
        ]
    },
    getters: {},
    mutations: {
        setUserProfile(state, payload) {
            state.userProfile = payload;
        },
        setActiveNavBar(state, payload) {
            state.adminNavBar = state.adminNavBar.map((nav) => {
                if(nav.name === payload) {
                    nav.is_active = true;
                }
                else {
                    nav.is_active = false;
                }

                return nav;
            })
        }
    },
    actions: {
        async loginUser(context, payload) {
            const {login, user} = authLogin(payload);
            await login();
            context.commit('setUserProfile', user);
        },
        async getUserProfile(context, payload) {
            const {loadProfile, user} = authProfile();
            await loadProfile();
            context.commit('setUserProfile', user);
        },
        async logoutUser(context, payload) {
            await authLogout();
        }   
    },
    modules: {}
})

export default store;